import React from "react";
// import "./styles.css"; // Import your CSS file

const ExampleNewsletter = () => {
  return (
    <div id="example-newsletter">
      <div className="container">
        <h1>Deals to Honolulu, Phoenix, Boise, and Tokyo!</h1>
        <br />
        <i>
          This week's travel deals include flights to the following locations in
          January, February, and March of 2024:
        </i>
        <ul
          className="flight-info"
          style={{ fontSize: "18px", fontWeight: "bold" }}
        >
          <li>Honolulu, Hawaii</li>
          <li>Phoenix, Arizona</li>
          <li>Boise, Idaho</li>
          <li>Tokyo, Japan</li>
        </ul>

        <div className="flight-info">
          <h2>Salt Lake City ✈️ Honolulu, Hawaii</h2>
          <h3>JANUARY 2024:</h3>
          <ul>
            <li>$350</li>
            <li className="flight-dates">
              January 10-20 | 13-20 | 13-21 | 16-20 | 16-21 | 17-20 | 17-21 |
              17-27 | 20-27 | 20-28 | 21-27
            </li>
          </ul>
          <ul>
            <li>$355</li>
            <li className="flight-dates">January 14-20 | 14-21</li>
          </ul>
          <ul>
            <li>$358</li>
            <li className="flight-dates">
              January 13-22 | 13-23 | 16-22 | 16-23 | 16-26 | 17-22 | 17-23 |
              17-26 | 19-29 | 20-22 | 20-23 | 20-26 | 22-27 | 23-27
            </li>
          </ul>
          <h3>FEBRUARY 2024:</h3>
          <ul>
            <li>$317</li>
            <li className="flight-dates">
              February 3-5 | 3-6 | 3-12 | 6-12 | 5-13 | 6-13
            </li>
          </ul>
          <ul>
            <li>$321</li>
            <li className="flight-dates">
              February 3-11 | 3-6 | 4-6 | 6-11 | 3-13 | 4-13 | 11-13
            </li>
          </ul>
          <ul>
            <li>$332</li>
            <li className="flight-dates">February 1-5 | 1-6</li>
          </ul>
        </div>
        <div className="flight-info">
          <h2>Salt Lake City ✈️ Phoenix, Arizona</h2>
          <h3>JANUARY 2024:</h3>
          <ul>
            <li>$38</li>
            <li className="flight-dates">
              January 9-13 | 9-16 | 9-17 | 10-13 | 10-16 | 10-17 | 10-20 | 13-16
              | 13-17 | 13-20 | 13-23 | 16-20 | 16-23 | 16-24 | 17-20 | 17-23
            </li>
          </ul>
          <ul>
            <li>$43</li>
            <li className="flight-dates">
              January 8-10 | 8-13 | 8-16 | 8-17 | 9-11 | 9-12 | 9-14 | 9-18 |
              9-19 | 10-12 | 10-14 | 10-18 | 10-19 | 11-13 | 13-18
            </li>
          </ul>
          <ul>
            <li>$48</li>
            <li className="flight-dates">
              January 6-9 | 6-10 | 6-13 | 6-16 | 7-9 | 7-10 | 7-13 | 7-16 | 7-17
              | 8-11 | 8-12 | 8-14 | 8-18 | 9-15 | 10-15 | 9-11 | 11-13 | 11-16
            </li>
          </ul>
          <h3>FEBRUARY 2024:</h3>
          <ul>
            <li>$38</li>
            <li className="flight-dates">
              February 3-7 | 3-10 | 3-6 | 6-10 | 6-14 | 3-13 | 7-13 | 10-13 |
              7-14 | 10-14
            </li>
          </ul>
          <ul>
            <li>$43</li>
            <li className="flight-dates">
              February 1-3 | 1-7 | 1-10 | 3-5 | 3-8 | 3-9 | 5-7 | 5-10 | 5-14 |
              1-6 | 2-6 | 6-8 | 6-9 | 3-12
            </li>
          </ul>
          <ul>
            <li>$48</li>
            <li className="flight-dates">
              February 1-5 | 1-8 | 1-9 | 3-6 | 3-13 | 4-7 | 4-10 | 4-14 | 2-5 |
              5-8 | 5-9 | 6-13 | 2-12
            </li>
          </ul>
        </div>
        <div className="flight-info">
          <h2>Provo ✈️ Phoenix, Arizona</h2>
          <h3>JANUARY 2024:</h3>
          <ul>
            <li>$58</li>
            <li className="flight-dates">
              January 6-9 | 6-11 | 6-13 | 7-9 | 7-11 | 7-13 | 8-11 | 8-13 | 8-18
              | 9-11 | 9-13 | 9-18 | 9-19 | 10-13 | 10-18 | 10-19 | 11-13 |
              11-18
            </li>
          </ul>
          <ul>
            <li>$64</li>
            <li className="flight-dates">
              January 9-18 | 11-18 | 13-18 | 18-22 | 18-23 | 18-25 | 18-26 |
              18-27 | 19-25 | 22-25 | 23-25 | 25-27
            </li>
          </ul>
          <ul>
            <li>$66</li>
            <li className="flight-dates">
              January 6-10 | 7-10 | 7-17 | 8-10 | 8-17 | 9-17 | 10-17 | 11-17 |
              10-14 | 14-17 | 9-15 | 11-15 | 13-15
            </li>
          </ul>
          <h3>FEBRUARY 2024:</h3>
          <ul>
            <li>$58</li>
            <li className="flight-dates">
              February 2-5 | 2-6 | 2-7 | 1-3 | 3-5 | 3-6 | 3-7 | 3-13 | 1-4 |
              2-4 | 4-6 | 4-7 | 4-13 | 4-14 | 1-6 | 6-13 | 6-14 | 1-10
            </li>
          </ul>
          <ul>
            <li>$64</li>
            <li className="flight-dates">
              February 1-3 | 1-5 | 1-6 | 1-7 | 1-11 | 2-11 | 3-11 | 5-11 | 6-11
              | 7-11 | 11-13 | 11-14 | 11-21 | 21-25 | 25-27
            </li>
          </ul>
          <ul>
            <li>$66</li>
            <li className="flight-dates">
              February 2-9 | 2-10 | 2-12 | 3-9 | 3-10 | 3-12 | 4-9 | 4-10 | 4-12
              | 1-5 | 2-5 | 3-5 | 5-7 | 5-13 | 5-14 | 6-9 | 6-10
            </li>
          </ul>
        </div>
        <div className="additional-flight-info">
          <h3>
            🌟 SUBSCRIBE NOW to receive exclusive biweekly deals directly in
            your inbox! 🌟
          </h3>
          <iframe
            title="beehiiv"
            src="https://embeds.beehiiv.com/25bc5d3c-adfc-4bd8-ab16-543cdfb9ad7f?slim=true"
            data-test-id="beehiiv-embed"
            height="52"
            frameBorder="0"
            scrolling="no"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ExampleNewsletter;
