import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import firebase from "firebase/compat/app";
import { initializeApp } from "firebase/app";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAysI6lbVOsIT5empVrKn6BlUs6Q72MZdc",
  authDomain: "lowpricetravels-24635.firebaseapp.com",
  projectId: "lowpricetravels-24635",
  storageBucket: "lowpricetravels-24635.appspot.com",
  messagingSenderId: "310649022957",
  appId: "1:310649022957:web:fe1fdcf94513196bc69fe4",
  measurementId: "G-TXPSQMBG6Z",
};

// Initialize Firebase
initializeApp(firebaseConfig);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
