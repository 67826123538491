import React from "react";

const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: "#FE737A",
        color: "white",
        textAlign: "center",
        padding: "20px 0",
      }}
    >
      <p>
        &copy; {new Date().getFullYear()} LowPriceTravels. All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
