import React from "react";

const Navbar = () => {
  const handleSmoothScroll = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <nav
      className="navbar"
      style={{
        backgroundColor: "#FE737A",
        color: "white",
        padding: "10px 20px",
      }}
    >
      <div
        className="navbar-content"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: "1000px",
          margin: "0 auto",
        }}
      >
        <div className="navbar-left">
          <span
            style={{
              letterSpacing: "1px",
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            LOWPRICETRAVELS
          </span>
        </div>
        <div className="navbar-right">
          <ul
            style={{
              display: "flex",
              listStyleType: "none",
              margin: "0",
              padding: "0",
              gap: "20px",
            }}
          >
            <li>
              <a
                href="#subscribe"
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
                onClick={(e) => handleSmoothScroll(e, "subscribe")}
              >
                Subscribe
              </a>
            </li>
            <li>
              <a
                href="#example-newsletter"
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
                onClick={(e) => handleSmoothScroll(e, "example-newsletter")}
              >
                Example
              </a>
            </li>
            <li>
              <a
                href="#explore"
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
                onClick={(e) => handleSmoothScroll(e, "explore")}
              >
                Explore
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
