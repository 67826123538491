import React from "react";
import ExampleImage from "../example.png";
import FlightSearch from "../FlightSearch.png";
import Itinerary1 from "../Itinerary1.png";
import Itinerary2 from "../Itinerary2.png";
import Itinerary3 from "../Itinerary3.png";
import ExampleNewsletter from "./example-newsletter";

const NewsletterPromotion = () => {
  return (
    <div>
      <div class="newsletter-container">
        <div class="newsletter-content">
          <div id="subscribe">
            <h2>
              LowPriceTravels AI scrapes the web to give you{" "}
              <span class="large">THE LOWEST</span> prices to travel from the
              SLC and Provo airports.
            </h2>
            <br />
            <p>
              Join the LowPriceTravels newsletter to receive bi-weekly updates
              of best dates and prices of flights to top travel destinations.
            </p>
          </div>

          <iframe
            title="beehiiv"
            src="https://embeds.beehiiv.com/25bc5d3c-adfc-4bd8-ab16-543cdfb9ad7f?slim=true"
            data-test-id="beehiiv-embed"
            height="52"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </div>
        <div class="newsletter-image">
          <img src={ExampleImage} alt="Travel Deals" />
        </div>
      </div>

      <div className="newsletter-section">
        <ExampleNewsletter />
      </div>

      <div className="two-sections" id="explore">
        <section
          className="flight-search-section"
          style={{ width: "100%", textAlign: "center" }}
        >
          <h2>
            Lowpricetravels.com includes a free flight search tool. Check it out
            now!
          </h2>
          <a
            href="https://lowpricetravels.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={FlightSearch}
              alt="Flight Search Tool"
              style={{ width: "100%" }}
            />
          </a>
        </section>

        <section className="itinerary-generator-section">
          <h1>Also, try our itinerary Generator</h1>
          <div className="itinerary-images">
            <div className="image-container">
              <img
                src={Itinerary1}
                alt="Itinerary 1"
                className="itinerary-image"
              />
            </div>
            <div className="image-container">
              <img
                src={Itinerary2}
                alt="Itinerary 2"
                className="itinerary-image"
              />
            </div>
            <div className="image-container">
              <img
                src={Itinerary3}
                alt="Itinerary 3"
                className="itinerary-image"
              />
            </div>
          </div>

          <a
            href="https://lowpricetravels.com"
            target="_blank"
            rel="noopener noreferrer"
            className="styled-button"
          >
            Visit lowpricetravels.com
          </a>
        </section>
      </div>
    </div>
  );
};

export default NewsletterPromotion;
